.twitter-typeahead {
  width: 100%; }

.tt-menu {
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  border-radius: 6px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box; }

.tt-suggestion {
  display: block;
  padding: 3px 20px; }

.tt-suggestion:hover,
.tt-suggestion.tt-cursor {
  color: #1e2125;
  background-color: #e9ecef; }

.tt-suggestion.tt-cursor a {
  color: #fff; }

.tt-suggestion p {
  margin: 0; }
